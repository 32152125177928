/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@include mat.core();

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

$ba-primary: (
  50: #e6ebf3,
  100: #c0cee0,
  200: #97aecc,
  300: #6d8db8,
  400: #4d74a8,
  500: #2e5c99,
  600: #295491,
  700: #234a86,
  800: #1d417c,
  900: #12306b,
  A100: #a1bdff,
  A200: #6e99ff,
  A400: #3b76ff,
  A700: #2164ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$ba-red: (
    50 : #f9e4e2,
    100 : #f0bcb7,
    200 : #e79087,
    300 : #dd6457,
    400 : #d54233,
    500 : #ce210f,
    600 : #c91d0d,
    700 : #c2180b,
    800 : #bc1408,
    900 : #b00b04,
    A100 : #ffdada,
    A200 : #ffa9a7,
    A400 : #ff7774,
    A700 : #ff5e5a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$ba-secondary: (
    50 : #e1e4e8,
    100 : #b3bbc6,
    200 : #818da0,
    300 : #4e5f7a,
    400 : #283d5e,
    500 : #021b41,
    600 : #02183b,
    700 : #011432,
    800 : #01102a,
    900 : #01081c,
    A100 : #5a6fff,
    A200 : #2742ff,
    A400 : #001ef3,
    A700 : #001bda,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$primary: mat.define-palette($ba-primary, 500);
$accent: mat.define-palette($ba-secondary, 500);
$warn : mat.define-palette($ba-red, 500);

$ebcp-theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  )
));

@include mat.all-component-themes($ebcp-theme);


.app {
  margin: 20px;

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 20px 0px;

    & > * {
      flex: 1;
      max-width: 100%;
    }
  }

  h2 {
    margin: 20px 0px;
  }
}
.form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}


.mat-mdc-form-field-icon-suffix{
  display:flex!important;
}

.custom-date-time-picker {
  width:100%!important;
  margin-right: 12px;
  font-size: 14px;
}
